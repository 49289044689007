<template>
  <div class="container-app">
    <div class="bg">
      <div class="arrow-container -expand-arrows-animation">
        <div class="arrow"></div>
        <div class="arrow"></div>
        <div class="arrow"></div>
        <div class="arrow"></div>
        <div class="arrow"></div>
      </div>
    </div>

    <nav class="navbar navbar-light bg-light">
      <img
        src="../../assets/logo_transparent.png"
        width="70"
        height="70"
        alt="Know Prep Logo"
      />
      <label for="c_sec">Section:</label>
      <select v-model="current_section" class="form-control" name="c_sec">
        <option
          v-for="(section, index) in sections"
          :key="index"
          :value="section.id"
        >
          {{ section.section_name }}
        </option>
      </select>
    </nav>
    <div class="heading">
      <h1>Test:{{ test_name }}</h1>
      <h4>Starts at:{{ getDate() }}</h4>
    </div>

    <div class="questions">
      <div class="prev-made-questions">
        <ol type="1">
          <li v-for="(question, index) in filters" :key="index">
            <div class="row" justify-content-between>
              <div class="col my-2 question">{{ question.question }}</div>
              <div class="col-1">({{ question.marks }})</div>
              <div class="col-1">({{ question.difficulty }})</div>
            </div>
            <ol v-if="question.propositions" type="A">
              <li
                v-for="(item, ind) in question.propositions"
                :key="ind"
                :style="
                  item['props'] == question.correct_option
                    ? { color: '#24cb24' }
                    : ''
                "
              >
                {{ item["props"] }}
              </li>
            </ol>
            <div v-if="!question.subType">
              <br />
              Answer: {{ question.correct_option }}
            </div>
            <div>
              <br />
              Explanation ({{ question.explanation }})
            </div>
            <button
              class="btn btn-danger my-3 mx-2"
              @click="deleteQuestion(question.question_id)"
            >
              Delete
            </button>
          </li>
        </ol>
      </div>
      <div class="row justify-content-center my-3" v-if="!edit_open">
        <div class="col d-flex justify-content-end">
          <button @click="edit_open = true" class="btn btn-primary px-5">
            Add MCQ
          </button>
        </div>
        <div class="col d-flex justify-content-start">
          <button
            @click="
              edit_open = true;
              mcq = false;
            "
            class="btn btn-primary"
          >
            Add Theoritical
          </button>
        </div>
      </div>
      <div class="mx-auto editable-ques my-5 py-3" v-else>
        <div>
          <textarea
            class="form-control mb-3"
            v-model="current_question"
            placeholder="Enter Question Here"
            rows="10"
            spellcheck="true"
          ></textarea>
        </div>
        <div v-if="mcq">
          <div
            class="row my-1"
            v-for="(item, index) in current_options"
            :key="index"
          >
            <input
              class="form-control col-1 align-self-center"
              type="radio"
              :value="index"
              v-model="selected_option"
            />
            <input
              class="form-control my-1 col"
              v-model="current_options[index]"
              :placeholder="'Option ' + (index + 1)"
            />
          </div>
          <div class="btn btn-primary ml-4 my-3" @click="addOption">
            Add Option
          </div>
        </div>
        <div v-else>
          <select v-model="subType" class="form-control mb-4" name="subType">
            <option disabled value="-1">Select Type of Question</option>
            <option value="0">OneWord</option>
            <option value="1">Long/Short Answer</option>
          </select>
        </div>
        <div v-if="!Type">
          <input
            class="form-control my-1 col"
            v-model="correct_option"
            maxlength="100"
            :placeholder="'Correct Answer'"
          />
        </div>
        <label>Marks</label>
        <div>
          <input
            class="form-control"
            type="number"
            id="marks"
            v-model="marks"
            min="1"
          />
        </div>
        <small>This question marks</small>
        <br />
        <span>Difficulty: </span>
        <select v-model="selected">
          <option disabled value="">Please select difficulty</option>
          <option>Easy</option>
          <option>Medium</option>
          <option>Hard</option>
        </select>
        <div>
          <textarea
            class="form-control mb-3"
            v-model="explanation"
            placeholder="Enter Explanation Here"
            rows="10"
            spellcheck="true"
          ></textarea>
        </div>
        <div>
          <button class="btn btn-primary" @click="validate1">
            Add Question
          </button>
        </div>
      </div>
      <div class="justify-content-center d-flex my-5">
        <button class="btn btn-success" @click="redirect">Home</button>
      </div>
    </div>
  </div>
</template>
<script>
import db from "../../firebase.js";
import axios from "axios";
import store from "@/store";
export default {
  name: "EditTest",

  props: ["test_id", "page_id", "test_name"],

  mounted() {
    //For this test-id set questions
    var config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    axios
      .post(
        "https://Pysberduck.pythonanywhere.com/api/edit-test",
        {
          test_id: this.test_id,
          page_id: this.page_id,
          demo: false,
        },
        config
      )
      .then(
        (res) => {
          if (res.data["status"] == "200") this.questions = res.data["data"];
          //Questions List
          else if (res.data == "500") swal("Test Not made yet");
          else if (res.data == "400") swal("Test does not exist");
          else swal("Some error occured");
          db.collection("tests" + this.page_id)
            .doc(this.test_id)
            .get()
            .then((doc) => {
              this.sections = doc.data().sections;
            });
        },
        (err) => {
          swal("Some error occured");
        }
      );
    store.state.loading = false;
  },
  data() {
    return {
      subType: 1,
      selected: "",
      selected_option: -1,
      title: "",
      start_time: this.$route.params.start_time,
      end_time: "",
      edit_open: false,
      mcq: true,
      current_question: "",
      current_options: ["", ""],
      correct_option: "",
      marks: -1,
      current_section: 0,
      explanation: "",
      questions: [],
      sections: [],
    };
  },
  computed: {
    Type() {
      if (this.subType == "0") this.subType = 0;
      else this.subType = 1;
      return this.subType;
    },
    total() {
      let sum = 0;
      this.questions.forEach((elem) => (sum += +elem["marks"]));
      return sum;
    },
    redirect() {
      this.$router.push({ name: "Admin" });
    },
    filters() {
      if (this.questions != null)
        return this.questions.filter((question) => {
          var x = this.sections[this.current_section];
          if (question.section == x.section_name) return true;
          else return false;
        });
      else return null;
    },
  },
  methods: {
    getDate() {
      var date = new Date(this.$route.params.start_time * 1000);

      if (date.toString() === "Invalid Date") {
        return this.$route.params.start_time;
      } else return date.toLocaleString();
    },
    validate1() {
      if (this.current_question.length == 0) {
        swal("Question Length should be greater than zero");
        return;
      }
      if (this.mcq) {
        if (this.selected_option == -1) {
          swal("Select a valid option");
          return;
        }
        this.correct_option = this.current_options[this.selected_option];
        for (var i = 0; i < this.current_options.length; i++) {
          if (this.current_options[i] == "") {
            swal("Fill up all options");
            return;
          }
        }
      }
      if (this.marks <= 0) {
        swal("Set marks >0");
        return;
      }
      //All conditions are fullfilled that means add this question to questions list

      var di = {};
      di["question"] = this.current_question;
      di["propositions"] = [];
      if (this.mcq) {
        for (var i = 0; i < this.current_options.length; i++) {
          di["propositions"].push({
            props: this.current_options[i],
          });
        }
      }

      di["correct_option"] = this.correct_option;
      di["marks"] = this.marks;
      di["section"] = this.sections[this.current_section].section_name;
      console.log(di["section"]);
      di["explanation"] = this.explanation;
      di["difficulty"] = this.selected;
      di["subType"] = this.subType;
      this.questions.push(di);
      this.saveQuestion();
      this.init();
    },
    init() {
      (this.edit_open = false),
        (this.mcq = true),
        (this.current_question = ""),
        (this.current_options = ["", ""]),
        (this.correct_option = ""),
        (this.explanation = ""),
        (this.marks = -1),
        (this.subType = 1);
    },
    addOption() {
      this.current_options.push("");
    },
    deleteQuestion(index) {
      db.collection("tests" + this.page_id)
        .doc(this.test_id)
        .collection("questions")
        .doc(index)
        .delete()
        .then(() => {
          var config = {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          };
          axios
            .post(
              "https://Pysberduck.pythonanywhere.com/api/edit-test",
              {
                test_id: this.test_id,
                page_id: this.page_id,
                demo: false,
              },
              config
            )
            .then(
              (res) => {
                if (res.data["status"] == "200")
                  this.questions = res.data["data"];
                else if (res.data == "500") swal("Test Not made yet");
                else if (res.data == "400") swal("Test does not exist");
                else swal("Some error occured");
                db.collection("tests" + this.page_id)
                  .doc(this.test_id)
                  .get()
                  .then((doc) => {
                    this.sections = doc.data().sections;
                  });
                console.log(this.sections);
              },
              (err) => {
                swal("Some error occured");
              }
            );
        });
    },
    async saveQuestion() {
      //Update the data on firestore
      //Now add all questions in
      var myid = 0;
      if (this.questions.length > 1)
        myid = this.questions[this.questions.length - 2].id + 1;
      var i = this.questions.length - 1;
      if (this.questions[i].subType == 0) {
        db.collection("tests" + this.page_id)
          .doc(this.test_id)
          .collection("questions")
          .add({
            question: this.questions[i].question,
            marks: +this.questions[i].marks,
            correct: this.questions[i].correct_option,
            difficulty: this.questions[i].difficulty,
            id: myid,
            section: this.questions[i].section,
            explanation: this.questions[i].explanation,
            subType: this.questions[i].subType, //all options
          })
          .then((doc) => {
            //doc.id
            this.questions[i]["id"] = myid;
            this.questions[i]["question_id"] = doc.id;
          });
      } else if (this.questions[i].propositions.length != 0) {
        db.collection("tests" + this.page_id)
          .doc(this.test_id)
          .collection("questions")
          .add({
            question: this.questions[i].question,
            marks: +this.questions[i].marks,
            correct: this.questions[i].correct_option,
            difficulty: this.questions[i].difficulty,
            id: myid,
            section: this.questions[i].section,
            explanation: this.questions[i].explanation,
            options: this.questions[i].propositions,
            subType: this.questions[i].subType, //all options
          })
          .then((doc) => {
            //doc.id
            this.questions[i]["id"] = myid;
            this.questions[i]["question_id"] = doc.id;
          });
      } else {
        db.collection("tests" + this.page_id)
          .doc(this.test_id)
          .collection("questions")
          .add({
            question: this.questions[i].question,
            section: this.questions[i].section,
            marks: +this.questions[i].marks,
            difficulty: this.questions[i].difficulty,
            explanation: this.questions[i].explanation,
            subType: this.questions[i].subType,
            id: myid,
          })
          .then((doc) => {
            //doc.id
            this.questions[i]["id"] = myid;
            this.questions[i]["question_id"] = doc.id;
          });
      }
      db.collection("tests" + this.page_id)
        .doc(this.test_id)
        .update({
          total: this.total,
        });
    },
  },
};
</script>
<style scoped>
.question {
  font-size: 20;
  font-weight: 600;
}
* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  letter-spacing: 2px;
}
.editable-ques {
  width: 60%;
  margin-top: 20px;
}
.header-quiz {
  display: flex;
  width: 100%;
  height: 20%;
  border-bottom: 1px solid #e7eae0;
  justify-content: center;
  align-items: center;
  background-color: #e7eae0;
  border-radius: 10px 10px 0px 0px;
}
.container-app {
  width: 100%;
  height: 100%;
  justify-content: center;
}
.container-quiz {
  display: flex;
  width: 40%;
  height: 85%;
  background-color: white;
  text-align: center;
  flex-flow: column;
  border: 1px solid #e7eae0;
  border-radius: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  align-items: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.questions {
  width: 70%;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 40px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.349);
  box-sizing: border-box;
  padding: 10vmin;
  transform: translate(25%, 50px);
}

button {
  border-radius: 12px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.btn-primary {
  background-color: #ff7a7a;
  border-top-color: #f88888;
  border-left-color: #d86d6d;
  border-right-color: #d86d6d;
  border-bottom-color: #bb5f5f;
  border-width: 4px;
}
.btn-success {
  background-color: #ff3c3c;
  border-top-color: #f88888;
  border-left-color: #d86d6d;
  border-right-color: #d86d6d;
  border-bottom-color: #bb5f5f;
  border-width: 4px;
}
.arrow {
  position: absolute;
  left: -15vmax;
  bottom: -25vmax;
  display: block;
  width: 30vmax;
  height: 30vmax;
  --arrow-scale: 0.5;
  background: #f74e4e;
  z-index: -1;
  cursor: pointer;
}

.-expand-arrows-animation .arrow {
  animation: expand-arrows 1s forwards;
}

.arrow:nth-child(2) {
  --arrow-scale: 1;
  background: #ff6868;
  z-index: -2;
}

.arrow:nth-child(3) {
  --arrow-scale: 1.5;
  background: #f77777;
  z-index: -3;
}

.arrow:nth-child(4) {
  --arrow-scale: 2;
  background: #f88e8e;
  z-index: -4;
}

.arrow:nth-child(5) {
  --arrow-scale: 2.5;
  background: #f09f9f;
  z-index: -5;
}

body {
  overflow-x: hidden;
}
@keyframes expand-arrows {
  0% {
    transform: scale(0) rotate(-45deg);
  }

  100% {
    transform: scale(var(--arrow-scale)) rotate(-45deg);
  }
}

.heading {
  width: max-content;
  margin-left: 20%;
}
.heading > h1 {
  font-family: "Londrina Solid", cursive;
  font-size: xx-large;
}
.heading > h4 {
  font-family: "Bodoni MT";
  font-size: medium;
}
.form-control {
  width: 80%;
}
</style>
